body {
    background: #ccc;
    background-image: none !important;
}

.header {
    padding: 20px 0 20px !important;
}

.container.page {
    max-width: 70%;

    @media (max-width:767px) {
        max-width: 95%;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    max-width: 70%;

    @media (max-width:767px) {
        max-width: 95%;
    }
}

.cardBox {
    width: 100%;
    display: flex;
    flex-direction: column;

    .card {
        border: 1px solid #1d1d1b;
        box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);
        -webkit-box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);
        -moz-box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);

        .cardImage {
            width: 100%;
            height: 350px;
            background-size: cover;
            position: relative;

            @media (min-height:626px) {
                background-position: center;
                height: 65vh;
            }

            @media (max-height:625px) {
                height: 300px;
                background-position: center;
            }

            @media (max-width:767px) {
                height: 50vh;
                background-position: center;
            }
            // img {
            //     width: 100%;
            //     height: 100%;
            // }

            .themeContainer {
                position: absolute;
                bottom: 0;
                width: 50%;
                background: #4c79bc;
                padding: 1rem;

                h1,
                span {
                    @media (min-height:626px) {
                        font-size: 2.5rem;
                    }

                    @media (max-height:625px) {
                        font-size: 1.5rem;
                    }

                    @media (max-width:767px) {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }


    .logo {
        display: flex;
        justify-content: flex-end;
        background-color: #fff;
        padding: 0 20px 0;

        img {
            width: 20%;
        }

        @media (max-width:767px) {
            img {
                width: 50%;
                height: 30px;
            }
        }
    }

    .message {
        white-space: pre-line;
        font-size: 2rem;
        font-family: "TheSans-SemiBold", sans-serif;
        padding: 1rem;
        line-height: 2.3rem;

        @media (max-width:767px) {
            font-size: 2rem;
        }

        @media (min-height:850px) {
            font-size: 3rem;
            line-height: 3.3rem;
        }
    }
}

.link {
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 0.8rem;

    @media (max-width:767px) {
        bottom: unset;
    }
}