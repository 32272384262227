h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}

.h1,
h1 {
    font-size: 2.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.h2,
h2 {
    font-size: 2rem;
}

.h4,
h4 {
    font-size: 1.5rem;
}

.pb2,
.py2 {
    padding-bottom: .5rem !important;
}

.pt2,
.py2 {
    padding-top: .5rem !important;
}

.pt4,
.py4 {
    padding-top: 1.5rem !important;
}

.p3 {
    padding: 1rem !important;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btnPrimary {
    color: #fff;
    background-color: #4c79bc;
    border-color: #4c79bc;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.col,
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12,
.colAuto,
.colLg,
.colLg1,
.colLg2,
.colLg3,
.colLg4,
.colLg5,
.colLg6,
.colLg7,
.colLg8,
.colLg9,
.colLg10,
.colLg11,
.colLg12,
.colLgAuto,
.colMd,
.colMd1,
.colMd2,
.colMd3,
.colMd4,
.colMd5,
.colMd6,
.colMd7,
.colMd8,
.colMd9,
.colMd10,
.colMd11,
.colMd12,
.colMdAuto,
.colSm,
.colSm1,
.colSm2,
.colSm3,
.colSm4,
.colSm5,
.colSm6,
.colSm7,
.colSm8,
.colSm9,
.colSm10,
.colSm11,
.colSm12,
.colSmAuto,
.colXl,
.colXl1,
.colXl2,
.colXl3,
.colXl4,
.colXl5,
.colXl6,
.colXl7,
.colXl8,
.colXl9,
.colXl10,
.colXl11,
.colXl12,
.colXlAuto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col,
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12,
.colAuto,
.colLg,
.colLg1,
.colLg2,
.colLg3,
.colLg4,
.colLg5,
.colLg6,
.colLg7,
.colLg8,
.colLg9,
.colLg10,
.colLg11,
.colLg12,
.colLgAuto,
.colMd,
.colMd1,
.colMd2,
.colMd3,
.colMd4,
.colMd5,
.colMd6,
.colMd7,
.colMd8,
.colMd9,
.colMd10,
.colMd11,
.colMd12,
.colMdAuto,
.colSm,
.colSm1,
.colSm2,
.colSm3,
.colSm4,
.colSm5,
.colSm6,
.colSm7,
.colSm8,
.colSm9,
.colSm10,
.colSm11,
.colSm12,
.colSmAuto,
.colXl,
.colXl1,
.colXl2,
.colXl3,
.colXl4,
.colXl5,
.colXl6,
.colXl7,
.colXl8,
.colXl9,
.colXl10,
.colXl11,
.colXl12,
.colXlAuto {
    padding-left: 0;
    padding-right: 0;
}

.mAuto {
    margin: auto !important;
}

.pt5,
.py5 {
    padding-top: 3rem !important;
}

.floatRight {
    float: right !important;
}

.root {
    width: 100%;
    background-image: url(../../images/home-hero.7971432.jpg); //url(../../images/Visual_OurCulture_15.jpg);
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: 576px) {
        max-width: 540px;
    }

    @media screen and (min-width: 768px) {
        max-width: 720px;
    }

    @media screen and (min-width: 992px) {

        max-width: 960px;
    }

    @media screen and (min-width: 1200px) {

        max-width: 1140px;
    }
}

.header {
    padding: 40px 0 20px;

    .logo {
        font-size: 60px;
        color: #fff;
        font-family: "TheSans-LP3Light", sans-serif;
        display: inline-block;

        & span {
            font-family: "TheSans-SemiBold", sans-serif;
        }
    }

    & a,
    & a:hover {
        text-decoration: none;
    }

    @media (max-width: 991.98px) {
        padding: 0;

        .logo {
            font-size: 40px;
        }
    }

    @media (max-height: 625px) {
        .logo {
            font-size: 30px;
        }
    }
}

.appLogo {
    background-image: url(../../images/strm-logo-dark.7d9a973.png);
    background-repeat: no-repeat;
    background-size: 222px 28px;
    width: 222px;
    height: 28px;
}

.footer {
    position: absolute;
    bottom: 75px;
    right: 85px;

    .appLogo {
        background-image: url(../../images/strm-logo.a36401d.png);
    }

    @media(max-width:991.98px) {
        .appLogo {
            background-size: 158px 20px;
            width: 158px;
            height: 20px;
        }
    }

    @media(max-height:625px) {
        top: 17px;
        right: 20px;
    }

    @media(max-width:767px) {
        top: 17px;
        right: 20px;
    }


    @media(max-height:768px) and (max-width:1024px) {
        top: 17px;
        right: 20px;
    }
}

.contentContainer.home {
    h1 {
        text-shadow: 2px 3px 10px #000;
    }

    .tagLine {
        font-family: "TheSans-SemiBold", sans-serif;
        text-shadow: 2px 3px 25px #000;
        line-height: 2.6rem;
        font-size: 2.4rem;
        padding-right: 60px;
        width: 33.333333%;

        br {
            display: block;
        }
    }

    @media screen and (min-width:768px) and (max-width:768px) and (min-height:1024px) and (max-height:1024px) {
        margin-top: 85px;
    }

    @media(max-width:767px) {
        display: none
    }
}

.messageBox {
    margin-top: 40px;

    .message {
        padding: 40px;
        box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);
        -webkit-box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);
        -moz-box-shadow: 0 0 36px -7px rgba(0, 0, 0, .73);
        background-color: #fff;
        width: 45%;

        .messageFooter {
            display: none;
        }

        h1,
        h4 {
            color: #36393a;
        }
    }

    @media(max-width:1199.98px) {
        .message {
            width: 60%
        }
    }

    @media(max-width:991.98px) {
        // margin-top: 0;

        .message {
            width: 60%
        }

    }

    @media(max-width:767.98px) {
        // margin-top: 0;

        .message {
            width: 100%
        }

        .message .message-footer {
            display: block;
        }
    }
}

.disclaimer {
    font-size: 12px;
    line-height: 14px;
    color: #36393a;

    @media(max-width:767.98px) {
        br {
            display: none;
        }
    }
}