@import "@fluentui/react/dist/sass/_References.scss";

$minRowHeight :30px;

@font-face {
    font-display: swap;
    font-family: "Webfont";
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/TheSans_LP4_SemiLight.v_202003121200.woff) format("woff");
}

@font-face {
    font-family: "Webfont";
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/TheSans_LP7_Bold.v_202003121200.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: TheSans-SemiBold;
    src: url(./fonts/TheSans_LP6_SemiBold.446a912.eot);
    src: url(./fonts/TheSans_LP6_SemiBold.446a912.eot?#iefix) format("embedded-opentype"), url(./fonts/TheSans_LP6_SemiBold.63cb943.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: TheSans-SemiLight;
    src: url(./fonts/TheSans_LP4_SemiLight.814964e.eot);
    src: url(./fonts/TheSans_LP4_SemiLight.814964e.eot?#iefix) format("embedded-opentype"), url(./fonts/TheSans_LP4_SemiLight.d49f2da.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: TheSans-LP3Light;
    src: url(./fonts/TheSans-LP3Lig.d305807.eot);
    src: url(./fonts/TheSans-LP3Lig.d305807.eot?#iefix) format("embedded-opentype"), url(./fonts/TheSans-LP3Lig.5fe0947.woff) format("woff");
    font-weight: 400;
    font-style: normal
}


* {
    box-sizing: border-box;
    font-family: "TheSans-LP3Light", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    //height: 100vh;
    display: flex;
    min-height: 100vh;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.bold {
    font-weight: 700;
}

.fullWidth {
    width: 100%;
}

.margin0 {
    margin: 0;
}

.marginTop10 {
    margin-top: 10px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.width50 {
    width: 50%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.width70 {
    width: 70%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.width80 {
    width: 80%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.width90 {
    width: 90%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.label {
    font-size: small;
    font-weight: 700;
}

.fitToContainer {
    width: 100%;
    height: 100%;
}

.fullHeight {
    height: 100%;
}

.required::after {
    content: '*';
    color: rgb(164, 38, 44);
    font-weight: bold;
    margin-left: 2px;
}

.columnRequestForm6ColLayout {
    @include ms-Grid-col;
    @include ms-xl2;
    @include ms-lg2;
    @include ms-md2;
    @include ms-sm12;
    min-height: $minRowHeight;
}

.columnRequestForm4ColLayout {
    @include ms-Grid-col;
    @include ms-xl3;
    @include ms-lg3;
    @include ms-md3;
    @include ms-sm12;
    min-height: $minRowHeight;
}

.columnRequestForm3ColLayout {
    @include ms-Grid-col;
    @include ms-xl4;
    @include ms-lg4;
    @include ms-md4;
    @include ms-sm12;
    min-height: $minRowHeight;
}

.columnRequestForm2ColLayout {
    @include ms-Grid-col;
    @include ms-xl6;
    @include ms-lg6;
    @include ms-md6;
    @include ms-sm12;
    min-height: $minRowHeight;
}

.columnRequestForm1ColLayout {
    @include ms-Grid-col;
    @include ms-xl12;
    @include ms-lg12;
    @include ms-md12;
    @include ms-sm12;
    min-height: $minRowHeight;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.autoHeight {
    height: auto;
}

.errorText {
    color: rgb(164, 38, 44);
    font-size: medium;
    font-weight: 600;
    margin-top: 10px;
    display: block;
}

.formRow4Col {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;

    .col {
        flex: 0 1 25%;
        display: flex;

        &:nth-child(odd) {
            padding: 6px 10px 0;
            justify-content: flex-end;
        }
    }

    .zeroPadding {
        &:nth-child(odd) {
            padding: 0 10px 0;
        }

    }

    @media screen and (max-width: 480px) {
        .col {
            min-width: 100%;

            &:nth-child(odd) {
                padding: 0;
                justify-content: flex-start;
            }

            &:nth-child(3) {
                margin-top: 15px;
            }
        }
    }

}

.labelCheckBox {
    margin-left: 8px;
}

.largeCheckBoxLabel {

    &>.ms-Checkbox-label {
        align-items: flex-start;
    }
}

.iconButton {
    border: 1px solid black;

    .ms-Icon {
        color: #323130;
    }

    &:hover {
        .ms-Icon {
            color: #0078d4;
        }

        // border: 1px solid #0078d4;
    }
}

.straumannBlueButton {
    border: 1px solid #467abd;
    background-color: #467abd;
    color: #fff;

    .ms-Icon {
        color: #fff;
    }

    &:hover {
        color: #fff;
        background-color: #2462A2;

        .ms-Icon {
            color: #fff;
        }
    }
}

.signInLabel {
    font-weight: 700;
    font-size: large;
}

.richTextLabel {
    white-space: pre-line;
}

.richTextLabelResponsive {
    white-space: pre-line;

    @media (max-width: 767px) {
        white-space: normal;
    }
}

.busyOverlay {
    // background: #00000052;

    .ms-Dialog-main {
        min-height: auto;
    }

    .ms-Dialog-header {
        .ms-Dialog-button {
            display: none;
        }
    }
}

.loggingInContainer {
    width: 100%;

    span {
        font-weight: 700;
        font-size: large;
        color: #000;
    }

    .loggingInLoader {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            display: inline-block;
            position: absolute;
            left: 8px;
            width: 16px;
            background: #000;
            animation: loggingInLoader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        }

        div {
            &:nth-child(1) {
                left: 8px;
                animation-delay: -0.24s;
            }

            &:nth-child(2) {
                left: 32px;
                animation-delay: -0.12s;
            }

            &:nth-child(3) {
                left: 56px;
                animation-delay: 0;
            }
        }


    }
}


@keyframes loggingInLoader {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}



.link {
    color: #467abd;
    cursor: pointer;
}

//####################################################################################//
// body.home,
// .myhivibe {
//     background-color: #ccc;
// }

body.home {
    height: 100%;
    // background-image: url(./images/home-hero.7971432.jpg);
    // background-position: 50%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-attachment: fixed;
}

a,
a:hover,
body {
    color: #4c79bc;
}

.text-primary {
    color: #4c79bc !important;
}

mgt-people-picker.custom-class {
    --input-border: 1px rgb(96, 94, 92) solid;
}

mgt-people-picker.custom-class.error {
    --input-border: 1px rgb(164, 38, 44) solid;
}

.red-text {
    color: rgb(164, 38, 44);
}

.error-text {
    padding-top: 5px;
    font-size: 0.7rem;
    color: rgb(164, 38, 44);
}

.green-text {
    color: rgb(16, 124, 16);
}

.outcome-message {
    font-size: 1rem;
    font-weight: 500;
    font-family: 'TheSans-SemiBold', sans-serif;
}

.message-bar {
    margin-bottom: 15px;
}