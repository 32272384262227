.fontIconText {
    font-size: 1rem;
    position: relative;
    top: 0;
}

.chooseTheme,
.chooseCard {
    font-size: 1.5rem;
    color: #cecece;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: #4c79bc;
    }
}

.themeContainerCallout {
    padding: 30px 15px;
    max-height: 90vh;
    overflow-y: scroll;

    &>h2 {
        font-size: 1.2rem;
        color: #4c79bc;
    }

    .themeList {
        margin-top: 20px;

        .themeTextContainer {
            padding: 2px 0;
            cursor: pointer;

            .themeText {

                color: #4c79bc;

                .fontIconTextTheme {
                    font-size: 0.8rem;
                    top: 2px;
                    position: relative;
                    color: #d6d6d6;
                }
            }

        }
    }

    @media(max-width:767.98px) {
        max-height: unset;
        overflow-y: unset;
        margin-bottom: 30px;
    }


}

.chooseSection {
    margin-bottom: 8px;

    & > h2 {
        width: 52.66667%;
    }

    .themeSelected {
        margin-left: 10px;

        & > div {
            color: #4c79bc;
            font-size: 0.8rem;
        }
    }

    .cardSelected {
        margin-left: 10px;
    }

    .cardThumbnail {
        width: 70px;
    }

    @media (max-width: 767px) {
        & > h2 {
            width: 41.7%;
        }
    }

    @media (max-width: 320px) {
        & > h2 {
            width: 42.4%;
        }
    }
}

.cardContainer {
    padding: 10px;
    max-height: 90vh;
    overflow-y: auto;
    max-width: 59vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: calc(100% + 20px);


    .card {
        width: 155px;
        height: 100px;
        cursor: pointer;
        margin: 9px;
        display: flex;
        justify-content: center;

        img {
            height: 100%;
            box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, .3);
            -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, .3);
            -moz-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, .3);
        }


    }

    @media(max-width:767.98px) {
        display: block;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: unset;

        .card {
            // width: 100%;
            // height: 100px;
            // cursor: pointer;
            // margin: 9px 0;
            flex: 1 0 auto;
            justify-content: center;
            align-items: center;

            img {
                height: auto;
                width: 100%;
            }
        }


    }
}

.row {
    margin-bottom: 15px;
}

.emojiContainer {
    position: relative;
    margin-top: 5px;

    .emojiIcon {
        cursor: pointer;
        color: #cecece;

        &:hover,
        &:active,
        &:focus {
            color: #4c79bc;
        }
    }

    .emojiPicker {
        position: absolute;
        bottom: 0;
        left: 20px;

        .closeBar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 10px 10px;
            border: 1px solid #d9d9d9;
            background-color: #fff;
            border-bottom: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .closeIcon {
                color: rgb(189, 64, 64);
                cursor: pointer;
            }
        }

        section {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    @media(max-width:1023px) {
        display: none;
    }
}

.submitCard {
    font-family: "TheSans-SemiBold", sans-serif;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.labelForm {
    padding: 0px;
    color: rgb(76, 121, 188);
    font-weight: 400;
    font-size: 0.8rem;
    height: auto;
    margin-bottom: 5px;
}

.useButton {
    height: 32px;
    line-height: 0;
    margin-left: 10px !important;
}